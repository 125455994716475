import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './index.module.scss'

const Project = props => (
  <div className={ styles.project }>
    <h1>{ props.title }</h1>
    <p className={ styles.projectDescription }>{ props.description }</p>
    <AniLink
      fade
      to={ props.href }>
      Gå til { props.title }
    </AniLink>
  </div>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Hjem" />
    <h2>Prosjekter</h2>
    <Project title="Bloggen" href="/blogg" description="indregard.no var en blogg fra 2006 til 2013. Her finner du det komplette arkivet." />
    <Project title="Mine minne" href="/minne" description="Prosa og dikt min farfar Andreas (1906-1977) skrev." />
  </Layout>
)

export default IndexPage
